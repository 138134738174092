import React, { memo } from "react";
import Image from "next/image";
import RateBadge from "components/Badges/Rate.badge";
import { User } from "types/business.types";
import dayjs from "dayjs";
import { resolvedPublicPath } from "utils/utils";

type ItemProps = {
	text: string;
	author: User;
	publishedAt: Date;
	score: number;
};

const ReviewCard = ({ text, author, publishedAt, score }: ItemProps) => {
	return (
		<div className="grid grid-cols-6 gap-3 items-start">
			<div className="col-span-6 md:col-span-2 lg:col-span-1 flex-wrap flex flex-row items-center">
				<div className="flex-none relative w-12 aspect-square mr-4">
					<Image
						width={48}
						height={48}
						loading="lazy"
						className="rounded-full"
						src={author.avatar?.url || resolvedPublicPath("review.svg")}
						alt={author.avatar?.alt || `${author.firstName} ${author.lastName}`}
					/>
				</div>
				<div className="flex-auto">
					<h4 className="font-medium capitalize mb-2 md:mb-0 md:max-w-[120px] lg:max-w-[80px] xl:max-w-[120px] truncate">
						{author.firstName} {author.lastName.charAt(0)}
					</h4>
					<div className="flex justify-between md:flex-col md:justify-start">
						<p className="text-sm text-[#46474a] md:order-1 order-3">
							{dayjs(publishedAt).format("DD/MM/YYYY")}
						</p>
						<div className="order-2">
							<RateBadge textClassName="text-primary-500" rate={score} />
						</div>
					</div>
				</div>
			</div>
			<div className="flex-auto p-5 rounded-xl rounded-tl-none bg-primary-50 w-full col-span-6 md:col-span-4 lg:col-span-5 break-word">
				{text}
			</div>
		</div>
	);
};

export default memo(ReviewCard);
