import React, { memo } from "react";
import classNames from "classnames";
import { MdStar } from "react-icons/md";

type Props = {
	rate: number;
	textClassName?: string;
};

function RateBadge({ rate, textClassName }: Props) {
	return (
		<div className={classNames("flex items-center space-x-1 text-sm", textClassName)}>
			<MdStar className="relative -top-[1px] w-4 h-4" />
			<span>{rate}</span>
		</div>
	);
}

export default memo(RateBadge);
