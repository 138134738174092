import { memo, useMemo } from "react";
import { useBusinessProfile, useReviews } from "hooks/useFetch";
import ReviewCard from "components/Reviews/Review.card";
import Loading from "components/Loading/Loading";
import useVirtualized from "hooks/useVirtualized";

type Props = {};

function ReviewsList({}: Props) {
	const { data: businessProfile } = useBusinessProfile();
	const widgetSetting = businessProfile?.data;
	const { data, error, fetchNextPage, status, isFetchingNextPage, isFetching } = useReviews(widgetSetting?.key);
	const reviews = useMemo(() => (data ? data.pages.flatMap((page) => page.items) : []), [data]);
	const lastPage = data?.pages[data.pageParams.length - 1];
	const hasNextPage = lastPage?.hasNext || false;
	const { parentRef, rowVirtualizer, items, totalSize } = useVirtualized({
		fetchNextPage,
		isFetchingNextPage,
		hasNextPage,
		length: reviews.length,
	});

	return (
		<>
			{status === "loading" ? (
				<Loading />
			) : status === "error" ? (
				<span>Error: {(error as Error).message}</span>
			) : (
				<div ref={parentRef}>
					<div
						className="relative w-full"
						style={{
							height: totalSize,
						}}
					>
						<div
							className="absolute top-0 left-0 w-full"
							style={{
								transform: `translateY(${items[0].start - rowVirtualizer.options.scrollMargin}px)`,
							}}
						>
							{items.map((virtualRow) => {
								const isLoaderRow = virtualRow.index > reviews.length - 1;
								const item = reviews[virtualRow.index];

								return (
									<div
										key={virtualRow.key}
										data-index={virtualRow.index}
										ref={rowVirtualizer.measureElement}
										className="py-4"
									>
										{isLoaderRow ? (
											hasNextPage ? (
												<Loading />
											) : (
												"Nothing more to load"
											)
										) : (
											<ReviewCard
												text={item.comment}
												publishedAt={item.publishedAt}
												score={item.score}
												author={item.author}
											/>
										)}
									</div>
								);
							})}
						</div>
					</div>
				</div>
			)}
			{isFetching && !isFetchingNextPage ? <Loading /> : null}
		</>
	);
}

export default memo(ReviewsList);
