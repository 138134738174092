import lowerCase from "lodash-es/lowerCase";
import kebabCase from "lodash-es/kebabCase";
// import pluralize from "pluralize";
import { BookUrlType, SocialUrlType } from "types/book.type";

const DOMAIN_URL = process.env.NEXT_PUBLIC_DOMAIN_URL;
const getNameOfDay = () => {
	const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
	const d = new Date();
	return days[d.getDay()];
};

const generateBookingURL = ({ uniqueIdentifier, type, id, locale }: BookUrlType) => {
	let link = `${DOMAIN_URL}/widget/${uniqueIdentifier}?lng=${locale}`;

	switch (type) {
		case "location":
			link += `&locations=${id}`;
			break;

		case "provider":
			link += `&op=~${id}`;
			break;

		case "service":
			link += `&op=${id}~`;
			break;

		default:
			break;
	}

	return link;
};

const generateSocialShare = ({ type, businessName, url }: SocialUrlType) => {
	let link = "";

	switch (type) {
		case "facebook":
			link += `https://facebook.com/sharer/sharer.php?u=${url}`;
			break;

		case "twitter":
			link += `https://twitter.com/intent/tweet/?text=${businessName}&url=${url}`;

			break;

		default:
			break;
	}

	return link;
};

const formatTime24 = (timeString: string) => {
	const [hourString, minute] = timeString.split(":");
	const hour = +hourString % 24;
	return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
};

const truncateString = (str: string, num: number = 13) => {
	if (str.length <= num) {
		return str;
	}
	return str.slice(0, num) + "...";
};

const isProd = () => process.env.NODE_ENV === "production";

const resolvedPublicPath = (path: string) => (isProd() ? `/b/${path}` : `/${path}`);

const toSearchEngineFriendlyWords = (input: string) => {
	return encodeURIComponent(kebabCase(lowerCase(input)));
};

const generateSectionLabel = (label: string | null | undefined, fallback: string, limit = 13) => {
	if (!label) return fallback;
	return truncateString(label, limit);
};

export {
	getNameOfDay,
	generateBookingURL,
	generateSocialShare,
	formatTime24,
	truncateString,
	resolvedPublicPath,
	isProd,
	toSearchEngineFriendlyWords,
	generateSectionLabel,
};
