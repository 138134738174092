import { useEffect, useRef } from "react";
import { PartialKeys, useWindowVirtualizer, VirtualizerOptions } from "@tanstack/react-virtual";
import useOnScreen from "./useOnScreen";
import { InfiniteQueryObserverResult } from "@tanstack/react-query";

type Options = PartialKeys<
	VirtualizerOptions<Window, Element>,
	"getScrollElement" | "observeElementRect" | "observeElementOffset" | "scrollToFn"
>;

type Props = {
	isFetchingNextPage: boolean;
	hasNextPage: boolean;
	length: number;
	options?: Options;
	fetchNextPage: () => Promise<InfiniteQueryObserverResult<unknown, unknown>>;
};

const useVirtualized = ({ isFetchingNextPage, hasNextPage, length, options, fetchNextPage }: Props) => {
	const parentOffsetRef = useRef<number>();
	const parentRef = useRef<HTMLDivElement>(null);
	const onScreen = useOnScreen(parentRef);

	const opts: Options = {
		count: hasNextPage ? length + 1 : length,
		scrollMargin: parentOffsetRef.current,
		estimateSize: () => 100,
		initialOffset: 3,
		overscan: 2,

		...options,
	};

	useEffect(() => {
		if (onScreen) {
			parentOffsetRef.current = parentRef.current?.offsetTop ?? 0;
		}
	}, [parentRef, onScreen]);

	const rowVirtualizer = useWindowVirtualizer(opts);

	const totalSize = rowVirtualizer.getTotalSize();
	const items = rowVirtualizer.getVirtualItems();

	useEffect(() => {
		const [lastItem] = [...items].reverse();

		if (!lastItem) {
			return;
		}

		if (lastItem.index >= length - 1 && hasNextPage && !isFetchingNextPage && onScreen) {
			fetchNextPage();
		}
	}, [hasNextPage, fetchNextPage, length, isFetchingNextPage, items, onScreen]);

	return {
		parentRef,
		rowVirtualizer,
		items,
		totalSize,
	};
};

export default useVirtualized;
