import { useState, useEffect } from "react";

const useOnScreen = (ref: React.RefObject<HTMLDivElement>, rootMargin = "0px") => {
	const [isIntersecting, setIntersecting] = useState(false);
	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				// Update our state when observer callback fires
				setIntersecting(entry.isIntersecting);
			},
			{
				rootMargin,
			}
		);
		if (ref.current) {
			observer.observe(ref.current);
		}
	}, [ref, rootMargin]); // Empty array ensures that effect is only run on mount and unmount
	return isIntersecting;
};

export default useOnScreen;
